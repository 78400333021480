import { logsLoading, logsError, setLogsList } from "../../slices/logsSlice";
import { getService } from "../../services/logs";

export const getAction = () => async (dispatch) => {
  dispatch(logsLoading());
  try {
    const response = await getService();
    if (response?.error) {
      dispatch(logsError(response.error));
      return;
    } else {
      dispatch(setLogsList(response));
      return;
    }
  } catch (error) {
    dispatch(logsError(error.message));
    return;
  }
};
