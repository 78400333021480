import Button from "@mui/material/Button";

const dynamicButton = ({ label, onClick }) => {
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={onClick}
      sx={{
        transition: "0.5s all",
        "&:hover": {
          backgroundColor: "#d32f2f",
          color: "white",
        },
      }}
    >
      {label}
    </Button>
  );
};

export default dynamicButton;
