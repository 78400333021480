import axios from "axios";

export const getService = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/logs`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`Error retrieving Logs data: ${error.message}`);
    });
};
