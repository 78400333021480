import React, { useEffect } from "react";
import DynamicTable from "../../components/DynamicTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import { Box } from "@mui/material";
import { getAction } from "../../store/actions/logs";
import { DecodeUserRole } from "../../hooks/userData";
const moment = require("moment");

const Logs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.logs.data);
  const loading = useSelector((state) => state.logs.loading);
  const error = useSelector((state) => state.logs.error);
  const userRole = DecodeUserRole();

  useEffect(() => {
    dispatch(getAction());
  }, [dispatch]);

  const filteredData = data?.logs?.map((logs) => {
    const { createdAt, updatedAt, timestamp, ...rest } = logs;

    const formattedDeletedAt = moment(timestamp).format("DD/MM/YYYY hh:mm A");

    return {
      ...rest,
      timestamp: formattedDeletedAt,
    };
  });

  return (
    <Box>
      {userRole === "superadmin" && (
        <>
          <Box>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage message={error} />
            ) : (
              <>
                <DynamicTable
                  data={filteredData}
                  canCreate={false}
                  canEdit={false}
                  canDelete={false}
                  canRestore={false}
                  table={"Action Logs"}
                  isLogsTable={true}
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Logs;
