import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import axios from "axios";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/login`,
        {
          username,
          password,
        }
      );

      const { data } = response;

      localStorage.setItem("token", data.token);

      onLogin(username);
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage("Invalid credentials");
      setUsername("");
      setPassword("");
      usernameRef.current.focus();
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={{
        width: "300px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f8f8f8",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Typography
        sx={{ textAlign: "center", fontSize: "32px", color: "#1976d2" }}
      >
        Login
      </Typography>
      <TextField
        required
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        helperText={errorMessage !== "" ? errorMessage : ""}
        error={errorMessage !== ""}
        inputRef={usernameRef}
      />

      <TextField
        required
        label="Password"
        type={showPassword ? "text" : "password"}
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        helperText={errorMessage !== "" ? errorMessage : ""}
        error={errorMessage !== ""}
        inputRef={passwordRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                  transition: "0.5s all",
                  "&:hover": {
                    color: "#1976d2",
                  },
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          type="submit"
          sx={{
            backgroundColor: "#1976d2",
            color: "white",
            transition: "0.5s all",
            "&:hover": {
              backgroundColor: "transparent",
              outline: "1.5px solid #1976d2",
              color: "#1976d2",
            },
          }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
