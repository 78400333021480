import Button from "@mui/material/Button";

const dynamicButton = ({ label, onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        outline: "1px solid #1976d2",
        color: "white",
        transition: "0.5s all",
        "&:hover": {
          outline: "1px solid #1976d2",
          backgroundColor: "transparent",
          color: "#1976d2",
        },
      }}
    >
      {label}
    </Button>
  );
};

export default dynamicButton;
