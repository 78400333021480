import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import attendeesReducer from "../slices/attendeesSlice";
import logsReducer from "../slices/logsSlice";

const store = configureStore({
  reducer: {
    attendees: attendeesReducer,
    logs: logsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
