import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
  Box,
  Button,
  IconButton,
  Pagination,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import {
  Delete,
  DeleteOutlineOutlined,
  Edit,
  EditOutlined,
  Restore,
  Add,
} from "@mui/icons-material";

import Loader from "./Loader";
import { useState } from "react";
import YouTube from "react-youtube";
import { useDispatch } from "react-redux";

const DynamicTable = ({
  data,
  setSelectedImage,
  setShowModal,
  setCreateModal,
  setEditModal,
  setDeleteRowModal,
  setDeleteAllModal,
  setRestoreRowModal,
  setRestoreAllModal,
  canCreate = true,
  canDelete = true,
  canEdit = true,
  canRestore = false,
  isLogsTable = false,
  staticPage = false,
  setSelectedRow,
  table,
  setSelectedTable,
  getAction,
  hasLang = false,
}) => {
  const dispatch = useDispatch();
  const tableHeaders = Object.keys((data && data[0]) || {});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const storedLanguage = localStorage.getItem("selectedLanguage");
  const [selectedLanguage, setSelectedLanguage] = useState(
    storedLanguage ? storedLanguage : "en"
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sliceStart = page * rowsPerPage;
  const sliceEnd = sliceStart + rowsPerPage;
  const slicedData = data?.slice(sliceStart, sliceEnd);

  const handleCreate = (table) => {
    setSelectedTable(table);
    setCreateModal(true);
  };

  const handleEdit = (row, table) => {
    setSelectedRow(row);
    setSelectedTable(table);
    if (row?.image) {
      setSelectedImage(row?.image);
    } else if (row?.url) {
      setSelectedImage(row?.url);
    }
    setEditModal(true);
  };

  function getYouTubeVideoId(url) {
    const videoIdPattern =
      /(?:\?v=|\/embed\/|\/watch\?v=|\/shorts\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(videoIdPattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2em" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography style={{ margin: "0", fontSize: "28px" }}>
          {table}
        </Typography>
        <Box style={{ display: "flex", gap: "20px" }}>
          {hasLang && (
            <FormControl>
              <InputLabel
                sx={{
                  display: "flex",
                  gap: "0.5em",
                  background: "white",
                  p: "0 0.5em",
                }}
              >
                <img
                  src={`/images/${selectedLanguage}Flag.png`}
                  alt=""
                  style={{ width: "25px", height: "25px" }}
                />
                <Typography>
                  {selectedLanguage === "en" ? "English" : "عربي"}
                </Typography>
              </InputLabel>
              <Select
                sx={{
                  "& .MuiMenuItem-root": {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  },
                  width: "150px",
                }}
                value={""}
                onChange={(event) => {
                  const language = event.target.value;
                  setSelectedLanguage(language);
                  localStorage.setItem("selectedLanguage", language);
                  dispatch(getAction(language));
                }}
              >
                <MenuItem value="en" sx={{ gap: "0.5em" }}>
                  <img
                    src="/images/enFlag.png"
                    alt=""
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Typography>English</Typography>
                </MenuItem>
                <MenuItem value="ar" sx={{ gap: "0.5em" }}>
                  <img
                    src="/images/arFlag.png"
                    alt=""
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Typography>عربي</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          )}
          {canDelete && (
            <Button
              variant="contained"
              color="error"
              size="small"
              startIcon={<Delete />}
              onClick={() => {
                setSelectedTable(table);
                setDeleteAllModal(true);
              }}
              sx={{
                transition: "0.5s all",
                "&:hover": {
                  outline: "1px solid #d32f2f",
                  backgroundColor: "white",
                  color: "#d32f2f",
                },
              }}
            >
              Delete All
            </Button>
          )}
          {canCreate && (
            <Button
              variant="contained"
              endIcon={<Add />}
              size="small"
              onClick={() => {
                handleCreate(table);
              }}
              sx={{
                outline: "1px solid #1976d2",
                color: "white",
                transition: "0.5s all",
                "&:hover": {
                  outline: "1px solid #1976d2",
                  backgroundColor: "transparent",
                  color: "#1976d2",
                },
              }}
            >
              Create
            </Button>
          )}

          {canRestore && (
            <Button
              variant="contained"
              endIcon={<Restore />}
              size="small"
              onClick={() => {
                setSelectedTable(table);
                setRestoreAllModal(true);
              }}
              sx={{
                outline: "1px solid #1976d2",
                color: "white",
                transition: "0.5s all",
                "&:hover": {
                  outline: "1px solid #1976d2",
                  backgroundColor: "transparent",
                  color: "#1976d2",
                },
              }}
            >
              Restore All
            </Button>
          )}
        </Box>
      </Box>

      <TableContainer>
        {slicedData && slicedData.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders?.map((header) => (
                  <TableCell key={header}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {header.toUpperCase()}
                    </Box>
                  </TableCell>
                ))}
                {!isLogsTable && (
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    ACTIONS
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedData?.map((row) => (
                <TableRow hover key={row.id}>
                  {Object.values(row).map((value) => (
                    <TableCell key={value}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        {Array.isArray(value) ? (
                          <Box>
                            {value.map((item, index) => (
                              <ul key={index} style={{ lineHeight: "5px" }}>
                                <li style={{ whiteSpace: "nowrap" }}>{item}</li>
                                {index !== value.length - 1 && <br />}
                              </ul>
                            ))}
                          </Box>
                        ) : typeof value === "string" ? (
                          value.startsWith("http") ? (
                            value.includes("youtube.com") ? (
                              <Box>
                                <YouTube
                                  videoId={getYouTubeVideoId(value)}
                                  opts={{ width: "100%", height: "200px" }}
                                />
                              </Box>
                            ) : value.includes("images") ? (
                              <Box>
                                <Avatar
                                  src={value}
                                  alt=""
                                  style={{
                                    cursor: "pointer",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  onClick={() => {
                                    setSelectedImage(value);
                                    setShowModal(true);
                                  }}
                                />
                              </Box>
                            ) : (
                              value
                            )
                          ) : (
                            value
                          )
                        ) : (
                          value
                        )}
                      </Box>
                    </TableCell>
                  ))}
                  {!isLogsTable && (
                    <TableCell>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "0.5em",
                        }}
                      >
                        {canEdit && (
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleEdit(row, table);
                            }}
                            sx={{
                              "&:hover": {
                                transition: "0.5s",
                                transform: "rotate(-45deg)",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "0.5em",
                              }}
                            >
                              <Edit
                                color="primary"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  transition: "0.5s all",
                                  "&:hover": {
                                    color: "transparent",
                                  },
                                }}
                              />
                              <EditOutlined color="primary" />
                            </Box>
                          </IconButton>
                        )}

                        {canRestore && (
                          <IconButton
                            aria-label="restore"
                            onClick={() => {
                              setSelectedRow(row);
                              setSelectedTable(table);
                              setRestoreRowModal(true);
                            }}
                            sx={{
                              "&:hover": {
                                transition: "0.5s",
                                transform: "rotate(-45deg)",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "0.5em",
                              }}
                            >
                              <Restore color="primary" />
                            </Box>
                          </IconButton>
                        )}

                        {canDelete && (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setSelectedRow(row);
                              setSelectedTable(table);
                              setDeleteRowModal(true);
                            }}
                            sx={{
                              "&:hover": {
                                transition: "0.5s",
                              },
                            }}
                          >
                            <Box
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "0.5em",
                              }}
                            >
                              <Delete
                                color="error"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  transition: "0.5s all",
                                  "&:hover": {
                                    color: "transparent",
                                  },
                                }}
                              />
                              <DeleteOutlineOutlined color="error" />
                            </Box>
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box>
            <Loader />
            {(!data || data.length === 0) && (
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No data available
              </Typography>
            )}
          </Box>
        )}
      </TableContainer>

      {!staticPage && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="start"
        >
          <Pagination
            count={Math.ceil(data?.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            sx={{
              "& .Mui-selected": {
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                },
              },
              "& .MuiButtonBase-root:not(.Mui-selected):hover": {
                outline: "1px solid #1976d2",
                backgroundColor: "white",
                color: "primary.main",
              },
            }}
          />

          <InputLabel id="rows-per-page-label">Rows Per Page</InputLabel>

          <Select
            labelId="rows-per-page-label"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            variant="outlined"
            label="Rows Per Page"
            sx={{ height: "40px" }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Stack>
      )}
    </Box>
  );
};

export default DynamicTable;
