import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

export const DecodeUserRole = () => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = token ? jwtDecode(token) : null;
    setUserRole(decodedToken ? decodedToken.role : null);
  }, []);

  return userRole;
};

export const DecodeUsername = () => {
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = token ? jwtDecode(token) : null;
    setUsername(decodedToken ? decodedToken.username : null);
  }, []);

  return username;
};
