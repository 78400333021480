import React, { useState, useEffect } from "react";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Loader from "./components/Loader";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loggedInUsername, setLoggedInUsername] = useState(null);

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setLoggedInUsername(username);
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("loggedInUsername", username);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setLoggedInUsername(null);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("loggedInUsername");
  };

  useEffect(() => {
    const isLoggedInStorage = localStorage.getItem("isLoggedIn");
    const usernameStorage = localStorage.getItem("loggedInUsername");
    if (isLoggedInStorage) {
      setIsLoggedIn(true);
    }

    if (usernameStorage) {
      setLoggedInUsername(usernameStorage);
    }

    setIsLoading(false);
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isLoggedIn ? (
            <Dashboard username={loggedInUsername} onLogout={handleLogout} />
          ) : (
            <Login onLogin={handleLogin} />
          )}
        </>
      )}
    </div>
  );
}

export default App;
