import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    logsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    logsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setLogsList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { logsLoading, logsError, setLogsList } = logsSlice.actions;

export default logsSlice.reducer;
