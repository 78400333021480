import React from "react";
import { Box, Modal } from "@mui/material";

const ImageModal = ({ image, onClose, showModal }) => {
  return (
    <Modal open={showModal} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
          outline: "none",
        }}
        onClick={onClose}
      >
        <img
          src={image}
          alt="Avatar"
          style={{ width: "100%", maxHeight: "80vh" }}
        />
      </Box>
    </Modal>
  );
};

export default ImageModal;
